// export const UNIVERSAL_ROUTER_ADDRESS = '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD'

let ROUTER_ADDRESS_DEPLOY = '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD'
const ROUTER_ADDRESS = {
  1: ROUTER_ADDRESS_DEPLOY,
  5: ROUTER_ADDRESS_DEPLOY,
}


function UNIVERSAL_ROUTER_ADDRESS(chainId: number): string {
  return ROUTER_ADDRESS[chainId];
}
export { UNIVERSAL_ROUTER_ADDRESS }
