function NewPairs() {
  return (
    <div className="new-pairs-container">
      UniDexAI concludes talks & signs up with @0xProject, the powerhouse behind the Coinbase wallet, to usher in a new
      era for our app. We're embarking on a complete migration to a cutting-edge framework, with our swap functionality
      being supercharged by @0xProject's API infrastructure.
    </div>
  )
}
export default NewPairs
