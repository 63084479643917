function OpenBeta() {
  return (
    <div className="beta-container">
      <a href="https://t.me/unidexdev" target="_blank" rel="noreferrer">
        <div className="beta-button">Report Bug</div>
      </a>
      <a href="https://t.me/mikescave" target="_blank" rel="noreferrer">
        <div className="beta-text">Development Build: Open Beta</div>
      </a>
    </div>
  )
}
export default OpenBeta
