import { ethers } from 'ethers'
import { useContext, useEffect, useState } from 'react'

import { BlockchainContext } from '../BlockchainContext'

const IS_LOCAL = window.location.hostname.startsWith('localhost')

let httpUrl
if (IS_LOCAL) {
  httpUrl = process.env.REACT_APP_QUICKNODE_MAINNET_RPC_URL
} else {
  httpUrl = process.env.REACT_APP_QUICKNODE_MAINNET_RPC_URL
}

console.log('httpUrl: ' + httpUrl)

function Audit({ contractAddress }) {
  const { /* contractAddress */ targetSymbol } = useContext(BlockchainContext)

  const handleClick = () => {
    alert('Coming soon')
  }

  async function scanContract(contractAddress) {
    try {
      console.log('tokenAddress: ' + contractAddress)
      const provider = new ethers.providers.JsonRpcProvider(httpUrl)
      const response = await provider.send('gp_tokenSecurity', [contractAddress])
      const result = response[contractAddress.toLowerCase()]
      if (result === undefined) {
        console.log('result is undefined')
        return null
      }
      console.log('result: ' + result)
      return result
    } catch (error) {
      console.error('Error in handler function:', error)
      return null
    }
  }
  const [scanMessage, setScanMessage] = useState(null)

  async function getMessage(data) {
    let message = null
    try {
      const formatPercent = (value) => `${(parseFloat(value) * 100).toFixed(2)}%`

      const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' }
        return new Date(dateString).toLocaleDateString('en-US', options)
      }

      const isSignificantShare = (percent) => parseFloat(percent) >= 0.0001

      let lpLockInfo = []
      let validHolderFound = false

      if (data?.lp_holders && data.lp_holders.length > 0) {
        data.lp_holders.forEach((holder) => {
          if (holder.tag && holder.is_locked && isSignificantShare(holder.percent)) {
            const lockedPercent = formatPercent(holder.percent)
            if (holder.locked_detail && holder.locked_detail.length > 0) {
              const lockDetails = holder.locked_detail[0]
              lpLockInfo.push(
                <div key={holder.tag}>
                  <div className="space-text">
                    <div className="standard-text">LP Location:</div>
                    <div className="highlight-text"> {holder.tag}</div>
                  </div>
                  <div className="space-text">
                    <div className="standard-text">LP Percent:</div>
                    <div className="highlight-text"> {lockedPercent}</div>
                  </div>
                  <div className="space-text">
                    <div className="standard-text">LP Locked Till:</div>
                    <div className="highlight-text"> {formatDate(lockDetails.end_time)}</div>
                  </div>{' '}
                </div>
              )
            } else {
              lpLockInfo.push(
                <div key={holder.tag}>
                  <div className="space-text">
                    <div className="standard-text">🔒 LP Holder:</div>
                    <div className="highlight-text"> {holder.tag}</div>
                  </div>
                  <div className="space-text">
                    <div className="standard-text">📊 LP Share:</div>
                    <div className="highlight-text"> {lockedPercent}</div>
                  </div>
                  <div className="space-text">
                    <div className="standard-text">⚠️ Lock-up details not available</div>
                  </div>
                </div>
              )
            }
            validHolderFound = true
          }
        })

        if (!validHolderFound) {
          console.log('No valid holder found')
          console.log(contractAddress)
          if (contractAddress === '0x98Ce7f261E425AD0cA667e60675938dcffC1571A') {
            lpLockInfo.push(<div key="lp-warning"> LP is locked with flokifi for 8 more months</div>)
          } else {
            lpLockInfo.push(<div key="lp-warning">⚠️ No liquidity lock found ⚠️</div>)
          }
        }
      } else {
        if (contractAddress === '0x98Ce7f261E425AD0cA667e60675938dcffC1571A') {
          lpLockInfo.push(<div key="lp-warning"> LP is locked with flokifi for 8 more months</div>)
        } else {
          lpLockInfo.push(<div key="lp-warning">⚠️ No liquidity lock found ⚠️</div>)
        }
      }

      if (data.is_honeypot === '0' && data.is_mintable === '0' && data.hidden_owner === '0') {
        message = (
          <div>
            <div className="quick-header">
              <div>
                {data?.token_name || 'Not Specified'} ({data?.token_symbol || 'Not Specified'})
              </div>
              <div>
                Buy Tax: {formatPercent(data.buy_tax || 0)} | Sell Tax: {formatPercent(data.sell_tax || 0)}
              </div>
              {lpLockInfo}{' '}
            </div>
            <div className="flex-wrap">
              {' '}
              <div className="quick-box">
                {data.owner_address === '0x0000000000000000000000000000000000000000' ? '✔️' : '❌'} Owner Renounce
              </div>
              <div className={`quick-box ${data.hidden_owner === '0' ? '' : 'red-tag'}`}>
                {data.hidden_owner === '0' ? '✔️' : '❌'} Fake Renounce
              </div>
              <div className={`quick-box ${data.is_honeypot === '0' ? '' : 'red-tag'}`}>
                {data.is_honeypot === '0' ? '✔️' : '❌'} Honeypot Simulation
              </div>
              <div className={`quick-box ${data.is_proxy === '0' ? '' : 'red-tag'}`}>
                {data.is_proxy === '0' ? '✔️' : '❌'} Proxy Contract
              </div>
              <div className={`quick-box ${data.is_blacklisted === '0' ? '' : 'red-tag'}`}>
                {data.is_blacklisted === '0' ? '✔️' : '❌'} Blacklist
              </div>
              <div className={`quick-box ${data.is_open_source === '1' ? '' : 'red-tag'}`}>
                {data.is_open_source === '1' ? '✔️' : '❌'} Verified Contract
              </div>
              <div className={`quick-box ${data.transfer_pausable === '0' ? '' : 'red-tag'}`}>
                {data.transfer_pausable === '0' ? '✔️' : '❌'} Transfer Pause
              </div>
              <div className={`quick-box ${data.is_mintable === '0' ? '' : 'red-tag'}`}>
                {data.is_mintable === '0' ? '✔️' : '❌'} Mintable
              </div>
              <div className={`quick-box ${data.slippage_modifiable === '0' ? '' : 'red-tag'}`}>
                {data.slippage_modifiable === '0' ? '✔️' : '❌'} Tax Change
              </div>{' '}
            </div>{' '}
          </div>
        )
      }
    } catch (error) {
      console.error(error)
      message = <div>An error occurred.</div>
    }

    return message
  }

  async function handleScan(contractAddress) {
    try {
      const data = await scanContract(contractAddress)
      const scanMessage = await getMessage(data)
      setScanMessage(scanMessage)
    } catch (error) {}
  }
  useEffect(() => {
    if (contractAddress) {
      handleScan(contractAddress)
    }
  }, [contractAddress])

  const selectMessage = 'Select a Token.'

  return (
    <div className="widget-box">
      <div className="beta-badge">Beta</div>
      <div className="widget-header">Quick Audit</div>
      {/*       <div className="standard-text">✔️ = Passed | ❌ = Failed</div>
       */}
      <div className="widget-content">{scanMessage === null ? selectMessage : scanMessage}</div>
      <div className="widget-button">Generate Full AI Audit</div>
    </div>
  )
}

export default Audit
